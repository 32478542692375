// Helpers
import { privateApiUrl, postRequest, putRequest, deleteRequest } from 'api/v3';

/**
 * Create a discussion for discussion topic
 * @param {string} discussableType
 * @param {(string|number)} discussableId
 * @param {string} comment
 * @param {(string|number|null)} parentId
 * @param {boolean} force - Allow toxic comment
 * @param {string} locale - ISO 369 code
 * @returns {Promise}
 */

export const createDiscussion = (
  discussableType,
  discussableId,
  comment,
  parentId,
  force = false,
  locale = 'en'
) => {
  const data = {
    discussable_type: discussableType,
    discussable_id: discussableId,
    body: `${comment}`,
    force,
  };

  if (parentId) {
    data.parent_id = parentId;
  }

  return postRequest(
    privateApiUrl('discussions', {
      locale,
    }),
    {
      data: {
        type: 'discussions',
        attributes: data,
      },
    }
  );
};

/**
 * Update a discussion attributes
 * @param {(number|string)} id
 * @param {object} body
 * @returns
 */
export const updateDiscussion = (id, body = {}) => {
  const url = privateApiUrl(`discussions/${id}`);
  return putRequest(url, {
    data: {
      type: 'discussions',
      attributes: body,
    },
  });
};

/**
 * Approve or reject a discussion
 * @param {object} discussion
 * @param {(number|string)} discussion.id
 * @param {string[]} discussion.moderationLabels
 * @param {boolean} approved
 * @returns
 */
export const approveDiscussion = (discussion, approved = true) => {
  // Remove the "Requires moderation" label
  const labels = discussion.moderationLabels.filter((label) => label !== 'Requires moderation');

  return updateDiscussion(discussion.id, {
    approved,
    require_moderation: false,
    moderation_labels: labels,
    force: true,
  });
};

/**
 * Get discussion replies URL
 * @param {(string|number)} id - discussion ID
 * @param {(string|number)} page
 * @param {(string|number)} size - item size
 * @returns {string}
 */
export const getDiscussionRepliesURL = (
  id,
  page = 1,
  size = 25,
  filter = {
    require_moderation: false,
  }
) => {
  return privateApiUrl(`discussions/${id}/discussion_replies`, {
    page: {
      size,
      number: page,
    },
    sort: '-created_at',
    filter,
  });
};

/**
 * Like a discussion
 * @param {(number|string)} discussionId
 */
export const createDiscussionLike = (discussionId) => {
  return postRequest(privateApiUrl(`discussions/${discussionId}/discussion_likes`), {
    data: {
      type: 'discussion_like',
      attributes: {},
    },
  });
};

/**
 * Cancel remove the like of the discussion
 * @param {(string|number)} discussionId
 * @param {(string|number)} discussionLikeId
 * @returns
 */
export const removeDiscussionLike = (discussionId, discussionLikeId) => {
  return deleteRequest(
    privateApiUrl(`discussions/${discussionId}/discussion_likes/${discussionLikeId}`)
  );
};

/**
 * Create a report for a discussion
 * @param {(string|number)} discussionId
 * @param {(string|number)} reasonId
 * @returns
 */
export const createDiscussionReport = (discussionId, reasonId, body) => {
  return postRequest(privateApiUrl(`discussions/${discussionId}/discussion_reports`), {
    data: {
      type: 'discussion_report',
      attributes: {
        discussion_report_reason_id: reasonId,
        body,
      },
    },
  });
};

/**
 * Mark the report_acknowledged of discussion reports of a discussion to true
 * @param {(string|number)} discussionId
 * @returns
 */
export const markReportsAcknowledges = (discussionId) => {
  const url = privateApiUrl(
    `discussions/${discussionId}/discussion_reports/acknowledge_all_reports`
  );

  return putRequest(url);
};

/**
 * Retrieve the thread for a discussion
 * @param {(number|string)} discussionId
 * @returns {string}
 */
export const getDiscussionThreadURL = (discussionId) => {
  return privateApiUrl(`discussions/${discussionId}/discussion_threads`);
};
